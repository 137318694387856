// extracted by mini-css-extract-plugin
export var firstContainer = "index-module--firstContainer--46805";
export var firstContainer__boxA = "index-module--firstContainer__boxA--4ace5";
export var firstContainer__boxB = "index-module--firstContainer__boxB--3c5e0";
export var firstContainer__boxC = "index-module--firstContainer__boxC--56d18";
export var firstContainer__boxD = "index-module--firstContainer__boxD--7127a";
export var firstContainer__boxE = "index-module--firstContainer__boxE--2e9ef";
export var firstContainer__imgWrapper = "index-module--firstContainer__imgWrapper--b3bbf";
export var heroSpace = "index-module--heroSpace--f88ff";
export var maxWidthZeroAuto = "index-module--maxWidthZeroAuto--cc0b1";
export var secondContainer = "index-module--secondContainer--46d3b";
export var secondContainer__boxA = "index-module--secondContainer__boxA--60506";
export var secondContainer__boxB = "index-module--secondContainer__boxB--51a7f";
export var secondContainer__boxC = "index-module--secondContainer__boxC--f7486";
export var secondContainer__inner = "index-module--secondContainer__inner--4aa17";