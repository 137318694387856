import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import { Seo } from "../components/seo"
import * as styles from "../components/index.module.css"

import { HeroHome } from "../components/heros/HeroHome"
import { SkipUberDelivery } from "../components/SkipUberLogos"

import RewardsImage from "../images/home/rewards_logo.svg"

const IndexPage = () => (
  <Layout>
    <HeroHome />
    <div className={`${styles.firstContainer} ${styles.maxWidthZeroAuto}`}>
      {/* boxA */}
      <div className={styles.firstContainer__boxA}>
        <p className="blueFont textCenter">WELCOME TO</p>
        <h1>THE COAL HARBOUR LIQUOR STORE</h1>
      </div>

      {/* boxB */}
      <div className={styles.firstContainer__boxB}>
        <p>
          Conveniently located on Pender Street between Bute and Jervis
          Streets, the Coal Harbour Liquor Store is easily accessible by Coal
          Harbour residents and Financial District workers and makes an easy
          stop for anyone driving East or West along the major arteries of
          Georgia, Melville or Pender Streets.
        </p>
        <p>
          With 3500 square feet of retail space, the store features an
          outstanding collection of domestic and imported wines, spirits and
          beer. Whether it's a treat for a special occasion or a favourite
          to go with dinner, the Coal Harbour Liquor Store will have just what
          you're looking for and with large, well-stocked coolers, your
          beer, wine and coolers are ready to serve the minute you pick them up.
        </p>
      </div>

      {/* boxC */}
      <div className={styles.firstContainer__boxC}>
        <StaticImage
          src="../images/home/coal-harbour-liquor-store-front-shop-v1.jpg"
          alt="coal harbour liquor store front of shop"
          aspectRatio={16 / 9}
        />
      </div>

      {/* boxD */}
      <div className={styles.firstContainer__boxD}>
        <StaticImage
          src="../images/home/coal-harbour-liquor-store-interior-v1.jpg"
          alt="coal harbour liquor store interior"
          aspectRatio={16 / 9}
        />
      </div>

      {/* boxE */}
      <div className={styles.firstContainer__boxE}>
        <h2>REWARDS PROGRAM</h2>
        <p className="blueFont textCenter">
          BE THE FIRST TO SEE OUR NEW AND UPDATED PRODUCTS
        </p>
        <div className={styles.firstContainer__imgWrapper}>
          <Link to="/rewards" title="Coal Harbour Rewards Program">
            <img
              src={RewardsImage}
              height="auto"
              width="auto"
              alt="rewards program logo"
            />
          </Link>
        </div>
      </div>
    </div>
    <SkipUberDelivery />
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Coal Harbour Liquor Store" canonical="https://coalharbourliquorstore.com" />

export default IndexPage
