import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styled from "@emotion/styled"

export function HeroHome() {
  const Wrapper = styled.div`
    display: grid;
    height: calc(100vh - 100px);
    margin-bottom: var(--space-4);
    
    .heroImg {
      grid-area: 1 / 1;
      z-index: 0;
    }

    .heroBoxB {
      z-index: 1;
      grid-area: 1 / 1;
      position: relative;
      place-items: center;
      display: grid;
    }

    .heroText {
      padding-left: var(--space-2);
      padding-right: var(--space-2);
    }
  `

  return (
    <Wrapper>
      <StaticImage
        className="heroImg"
        src="../../images/home/homeHero.jpg"
        alt="boats in the coal harbour marina"
        layout="fullWidth"
        // aspectRatio={2.75 / 1}
        // placeholder="tracedSVG"
        loading="eager"
      />
      <div className="heroBoxB">
        <div className="heroText">{/* intentionally blank */}</div>
      </div>
    </Wrapper>
  )
}
